:root {
    --black: #494848;
    --white: #f5f5f5;
    --background: var(--white);
    --foreground: var(--black);
    --transition: 0.5s ease;
  }

.hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

.toggle-wrapper {
    width: 55px;
    display: block;
    margin: auto; /* Centering for demo */
    margin-right: 5px !important;
    margin-left: 10px !important;
  }

  .toggle {
    height: 24px;
    width: 55px;
    background: var(--foreground);
    border-radius: 24px;
    padding: 12px;
    position: relative;
    transition: background var(--transition);
    cursor: pointer;
  }

  .toggle::before {
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    bottom: 4px;
    left: 4px;
    background: var(--background);
    position: absolute;
    z-index: 2;
    transform: translate(0);
    transition: transform var(--transition), background var(--transition);
  }

  .toggle.enabled::before {
    transform: translateX(30px);
  }

  .toggle input {
    opacity: 0;
    position: absolute;
    top: 0;
  }

  .toggle .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 -4px;
  }

  .toggle .icons svg {
    fill: var(--background);
    height: 12px;
    width: 12px;
    z-index: 0;
  }
